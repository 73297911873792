<template>
  <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.55615 15.6429H8.05616C6.66059 15.6429 5.96281 15.6429 5.39501 15.8151C4.11661 16.2029 3.11619 17.2033 2.72839 18.4817C2.55615 19.0495 2.55615 19.7473 2.55615 21.1429M15.0562 7.64288C15.0562 10.1282 13.0414 12.1429 10.5562 12.1429C8.07087 12.1429 6.05615 10.1282 6.05615 7.64288C6.05615 5.1576 8.07087 3.14288 10.5562 3.14288C13.0414 3.14288 15.0562 5.1576 15.0562 7.64288ZM11.5562 21.1429L14.6575 20.2568C14.806 20.2144 14.8803 20.1931 14.9495 20.1613C15.011 20.1331 15.0695 20.0987 15.1241 20.0586C15.1855 20.0135 15.2401 19.9589 15.3493 19.8497L21.8062 13.3929C22.4966 12.7026 22.4966 11.5832 21.8062 10.8929C21.1158 10.2025 19.9965 10.2025 19.3062 10.8929L12.8493 17.3497C12.7401 17.4589 12.6855 17.5135 12.6404 17.575C12.6003 17.6295 12.5659 17.688 12.5377 17.7495C12.5059 17.8188 12.4847 17.893 12.4423 18.0415L11.5562 21.1429Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconUserEditSolid'
}
</script>
